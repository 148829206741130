import React from 'react';
import {
  PopupTypeset,
  NewPopupTypeset,
  PopUp,
  PrintManager,
  LodopFunc,
  ShowInfo,
  PopTip,
  UploadImgCamera,
  FilterMore,
  PopupSortSet,
} from 'components';
import {
  callApi,
  isset,
  fromJS,
  remDub,
  createTip,
  showInfo,
  fetchApi,
  groupIdxSum,
  getPrintComId,
  typeIs,
  isEmptyObj,
  flatV1,
  getIsNewTableStyle,
  getGId,
} from 'utils';
import {
  CHECK,
  ERROR,
  INFO,
  GROUP_ID,
  MAX_TABLE_HEAD_COLS,
  MAX_TABLE_FILTER_ITEMS,
  MAX_TABLE_SOTR_ITEMS,
  SORTTYPES,
} from 'constants';
import actions from 'actions';
import { apiPrintTpl } from 'components/commoncomponents/print/printFaultTip';
import { checkLODOP } from 'utils/business/print/checkLODOP';
import { handleApiResPrint, checkComId, handlePrintTableList, noPrintBill } from 'utils/business/print/printTableList';
import printSlnDialog from 'components/commoncomponents/printSlnDialog';
import BatchOp from '../BatchOp';
import { tabKeyForUpgradeGuideOptions, headerFuncOpRowEvent } from './headerFuncOp/index';
import _ from 'lodash';
import { getNtoccOssKey } from '@/utils/oss';
import { getFileType } from '@/utils/getFileType';
import { WARN } from 'constants/index';

const TIPS = '请求服务器超时';
const mockData = require('./commonOp/mockData');

const checkSelected = function (selected, tips = '请选择操作项', one) {
  if (!selected || selected.length <= 0) {
    createTip(tips, INFO).show();
    return false;
  } else if (one && selected.length > 1) {
    createTip(tips, INFO).show();
    return false;
  }
  return true;
};

export { handleApiResPrint, handlePrintTableList };

// 列表设置字典未结应收其它费，已结应收其它费 ->(未结应收(字典)费，已结应收（月结）费)
const listSettingWord = (data, { tableKey } = {}) => {
  const Data = data.header;
  const setting = window.company_setting;
  const personSetting = window.psn_setting;
  const specialWordSetting = setting.special_field_tb || {};
  const specialWordList = specialWordSetting.tableData || [];
  const filed_list = Object.keys(Data);
  filed_list.map(item => {
    specialWordList.map(val => {
      const checkedTable = tableKey ? val.table?.find?.(itam => itam?.key === tableKey) : true; // 没有指定tableKey时，默认全部处理
      if (val.sys_field === Data[item].title && checkedTable) {
        Data[item].title = val.custom_field;
      }
      return null;
    });
    return null;
  });
  return { ...data, header: Data };
};
export switchSetTpl from 'pages/ListPage/commonOp/switchListTpl';

const getTableKey = (categoryTab, separator = '|') => {
  if (!categoryTab) return '';
  const key = [categoryTab.category, flatV1(categoryTab.tab)].join(separator);
  return key;
};

// 请求header接口
export const getHeader = (para, resPath = 'data.res') =>
  callApi({
    method: 'POST',
    path: 'Table/Index/getTable',
    body: {
      req: JSON.stringify({ items: ['header', 'limit_fields'], ...para }),
    },
  }).then(res => {
    const nRes = listSettingWord(res.data.res, { tableKey: getTableKey(para) });
    res.data.res = nRes;
    if (isset(res, 'data.errno') === 0) {
      return isset(res, resPath, {});
    }
    createTip(isset(res, resPath, {}) || TIPS, ERROR).show();
  });

/* 全站的列表设置、排序设置、筛选设置干掉恢复默认 17.09.28 */
// 列表设置
const handleOk = async (that, getvModalDialog, getvTypeset, para, ext, maxCols = MAX_TABLE_HEAD_COLS) => {
  const vTypeset = getvTypeset();
  const { rightList } = vTypeset.state;
  const oriRightList = vTypeset.props.rightList;
  const rightListKeys = rightList.map(item => item.key);
  const deleteList = oriRightList.filter(item => !rightListKeys.includes(item.key));
  if (rightListKeys.length > maxCols) {
    createTip(`列表最多可显示${maxCols}个字段，请重新选择`, INFO).show();
    that.handleStopLoading && that.handleStopLoading();
    return false;
  }
  that.handleStartLoading && that.handleStartLoading();
  getvModalDialog && getvModalDialog.submitBtnRef && getvModalDialog.submitBtnRef.loadingOn();
  const res = await callApi({
    method: 'POST',
    path: 'Table/Index/setTable',
    body: { req: JSON.stringify(Object.assign({ display_shown_fields: rightListKeys }, para)) },
  });
  if (isset(res, 'data.errno') === 0) {
    createTip('操作成功', CHECK).show();
    if (!that.isDetailProxyListPage && (that.getAllFitlerQuery || that.setSearchFilter)) {
      const filter = that.getAllFitlerQuery(true);
      const header = that.getHeader();
      const oriFilter = that.getOriFilter();
      const deFilter = that._designateFilter;
      deleteList.forEach(item => {
        if (!filter[item.key] && !oriFilter[item.key]) {
          delete filter[item.key];
          delete header[item.key];
          delete deFilter[item.key];
        }
      });
      that.setDesignateFilter && that.setDesignateFilter(deFilter);
      that.setSearchFilter && that.setSearchFilter(filter);
      that.setHeader && that.setHeader(header);
    }
    that.handleRefreshPage && that.handleRefreshPage();
    ext && ext.callback && ext.callback();
    getvModalDialog.vModalDialog.handleHide();
  } else {
    createTip(isset(res, 'data.errmsg', '') || TIPS, ERROR).show();
    that.handleStopLoading && that.handleStopLoading();
    getvModalDialog && getvModalDialog.submitBtnRef && getvModalDialog.submitBtnRef.loadingOff();
  }
};
// 列表设置弹框
export const setHeaderFunc = function (headerData = {}, that, para, ext) {
  let { header, limit_fields: maxCols } = headerData;
  let vTypset = null;
  // 兼容之前的格式
  if (!header && !maxCols) {
    header = headerData;
  }
  if (!header) return;
  maxCols = maxCols || MAX_TABLE_FILTER_ITEMS;
  const getvTypet = () => vTypset;
  const headerArr = Object.entries(header).map(([key, value]) =>
    value
      ? { key, title: value.title, d: value.display, tags: [...(value?.tags || [])] || [], ext: { ...value } }
      : undefined,
  );
  let rightList = headerArr.filter(item => item.d === 'show');
  // 由于之前的冻结是有stickyIndex来实现，为了兼容以前的数据，需要把stickIndex之前的数据手动增加sticky为true
  const stickyIndex = _.findLastIndex(rightList, e => e?.ext?.sticky);
  const stickRightGroup = [];
  for (let i = rightList.length - 1; i >= 0; i--) {
    if (i <= stickyIndex) {
      _.set(rightList[i], ['ext', 'sticky'], true);
    }
    // sticky_right需要重新排序，放在末尾去
    if (rightList[i]?.ext?.sticky_right) {
      // 拉取索引为index的元素
      const pulled = _.pullAt(rightList, [i]);
      stickRightGroup.unshift(pulled[0]);
    }
  }
  rightList = [...rightList, ...stickRightGroup].map(el => {
    if (el?.ext?.sticky || el?.ext?.sticky_right) {
      return {
        ...el,
        tags: [...el.tags, 'freezeImg'],
        dragAble: false,
        tips: '冻结项不可拖动',
      };
    }
    return {
      ...el,
    };
  });
  const stickyRightIndex = _.findIndex(rightList, e => e?.ext?.sticky_right);
  const leftList = headerArr.filter(item => item.d === 'hide');
  const tabKey = flatV1(para?.tab);
  const extOp = tabKeyForUpgradeGuideOptions[tabKey];
  const isSimple = window.simple_version;
  // 通过是否有customRender展示对应图标
  const customRender = {};
  if (extOp && isSimple) {
    const eventList = extOp?.eventList || [];
    eventList.forEach(eventName => {
      customRender[eventName] = headerFuncOpRowEvent[eventName]?.(extOp);
    });
  }
  const renderFreezeImg = {
    freezeImg: headerFuncOpRowEvent.freezeImg(),
  };
  const popParam = {
    isShow: true,
    title: '列表设置',
    autoDestroy: true,
    isModal: ext?.isModal ?? false, // 默认值是 false
    hasReset: ext !== undefined && ext.handleReset !== undefined,
    handleSubmit: typesetDialog => {
      ext && ext.handleOk && ext.handleOk(that, typesetDialog, getvTypet, para, ext, maxCols);
      !(ext && ext.handleOk) && handleOk(that, typesetDialog, getvTypet, para, ext, maxCols);
    },
    typesetProps: {
      ref: r => (vTypset = r),
      maxCountTip: `列表最多只能显示${maxCols}个字段`,
      titleType: 'setHeader',
      maxCols,
      leftListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
        customRender,
      },
      rightListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
        customRender: {
          ...renderFreezeImg,
        },
      },
      uniqueKey: 'key',
      leftList,
      rightList,
      stickyIndex: stickyIndex === -1 ? undefined : stickyIndex,
      stickyRightIndex: stickyRightIndex === -1 ? undefined : stickyRightIndex,
    },
  };
  ext &&
    ext.handleReset &&
    (popParam.handleReset = typesetDialog => {
      ext.handleReset(that, typesetDialog, getvTypet, para, ext);
    });
  const isNewTableStyle = getIsNewTableStyle();
  if (!isNewTableStyle) {
    return new PopUp(PopupTypeset, popParam).show();
  }
  new PopUp(NewPopupTypeset, popParam).show();
};
// 列表页操作：列表设置
export const setHeader = function (that, ext) {
  that.disableMenuButton && that.disableMenuButton('config');
  const categoryTab = {
    category: that.tableInfo.category,
    tab: that.tableInfo.tab,
  };
  getHeader(categoryTab).then(header => {
    setHeaderFunc(header, that, categoryTab, ext);
    that.enableMenuButton && that.enableMenuButton('config');
  });
};
// 筛选设置保存
const handleFilterConfigOk = (that, getvModalDialog, getvTypeset, para, defaultQuery, succCb) => {
  console.log('handleFilterConfigOk');
  const vTypeset = getvTypeset();
  const { rightList } = vTypeset.state;
  const oriRightList = vTypeset.props.rightList;
  let rightListKeys = rightList.map(item => item.key);
  // 保存的时候加上默认的筛选条件
  if (defaultQuery) {
    rightListKeys = [...rightListKeys, ...defaultQuery];
  }
  const deleteList = oriRightList.filter(item => !rightListKeys.includes(item.key));
  if (rightListKeys.length > MAX_TABLE_FILTER_ITEMS) {
    createTip(`筛选区最多可显示${MAX_TABLE_FILTER_ITEMS}个字段，请重新选择`, INFO).show();
    return false;
  }
  getvModalDialog && getvModalDialog.submitBtnRef && getvModalDialog.submitBtnRef.loadingOn();
  callApi({
    method: 'POST',
    path: 'Table/Index/setTable',
    body: {
      req: JSON.stringify({ search_filter: rightListKeys, ...para }),
    },
  }).then(res => {
    if (isset(res, 'data.errno') === 0) {
      createTip('操作成功', CHECK).show();
      if (that.getAllFitlerQuery || that.setSearchFilter) {
        const filter = that.getAllFitlerQuery(true);
        const deFilter = that._designateFilter;
        const header = that.getHeader();
        // TODO filter 里的key值 有在assembly里的 要拿出来判断
        deleteList.forEach(item => {
          // 在表头不存在 或不显示时 删除筛选项
          if (header[item.key] && (header[item.key].display === undefined || header[item.key].display === 'hide')) {
            delete filter[item.key];
            delete deFilter[item.key];
          }
        });
        that.setDesignateFilter && that.setDesignateFilter(deFilter);
        that.setSearchFilter && that.setSearchFilter(filter);
      }
      /**
       * 对账工具 - 报表 - 数具报表查询 - 这面面的设置筛选项回调
       */
      succCb && succCb();
      that.handleRefreshPage && that.handleRefreshPage();
      getvModalDialog.vModalDialog.handleHide();
    } else {
      createTip(isset(res, 'data.errmsg', '') || TIPS, ERROR).show();
      getvModalDialog && getvModalDialog.submitBtnRef && getvModalDialog.submitBtnRef.loadingOff();
    }
  });
};
// 筛选设置
export const setFilterFunc = function (h, that, categoryTab, ext, succCb) {
  const header = h && h.header ? h.header : h;
  if (!header) return;
  let vTypset = null;
  let filterKeys;
  const getvTypet = () => vTypset;
  if (ext && ext.filterKeys) {
    filterKeys = ext.filterKeys || [];
  } else {
    filterKeys = Object.keys(that.getOriFilter());
  }
  // 在「筛选设置」中隐藏字段，目前在「客户运营报表」和 「项目运营报表」使用到了该字段
  // 主要场景是，列表中存在两个相同中文名称的列，在筛选设置里需要隐藏一个列。
  // that可能存在2种值，当为ListPage实例的时候，才走隐藏逻辑
  const forceHideFilter = Object.values(that?.getHeader?.() ?? {})
    .filter(item => _.get(item, 'filterProps.forceHideInSetFilter'))
    .map(item => item.columnKey);
  const defaultQuery = Object.keys(ext.defaultQuery || {}) || [];
  const rightList = filterKeys
    .map(key => (header[key] && !defaultQuery.includes(key) ? { key, title: header[key].title } : undefined))
    .filter(item => item);
  const leftList = [];
  Object.entries(header).forEach(([key, value]) => {
    if (forceHideFilter && forceHideFilter.includes(key)) return;
    if (`${value.filterable}` === 'true' && !filterKeys.includes(key) && !defaultQuery.includes(key)) {
      leftList.push({ key, title: value.title });
    }
  });
  let headerTip = '';
  const hideEleTitle = [];
  defaultQuery.forEach(item => header[item] && hideEleTitle.push(header[item].title));
  if (hideEleTitle.length) {
    headerTip = ` * [${hideEleTitle.join('、')}]是系统默认筛选条件，不支持在筛选设置里隐藏。`;
  }
  const popParam = {
    isShow: true,
    title: '筛选设置',
    autoDestroy: true,
    hasReset: ext !== undefined && ext.handleReset !== undefined,
    handleSubmit: typesetDialog => {
      ext && ext.handleOk && ext.handleOk(that, typesetDialog, getvTypet, ext);
      if (!(ext && ext.handleOk)) {
        handleFilterConfigOk(that, typesetDialog, getvTypet, categoryTab, defaultQuery, succCb);
      }
    },
    headerTip,
    typesetProps: {
      ref: r => (vTypset = r),
      maxCountTip: `最多只能设置${MAX_TABLE_FILTER_ITEMS}个筛选条件`,
      titleType: 'setFilter',
      leftListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
      },
      rightListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
      },
      uniqueKey: 'key',
      leftList,
      rightList,
    },
  };
  ext &&
    ext.handleReset &&
    (popParam.handleReset = typesetDialog => {
      ext.handleReset(that, typesetDialog, getvTypet, categoryTab);
    });
  const isNewTableStyle = getIsNewTableStyle();
  if (!isNewTableStyle) {
    return new PopUp(PopupTypeset, popParam).show();
  }
  new PopUp(NewPopupTypeset, popParam).show();
};
// 列表页筛选设置
export const setFilter = function (that) {
  that.disableMenuButton && that.disableMenuButton('config');
  const categoryTab = {
    category: that.tableInfo.category,
    tab: that.tableInfo.tab,
  };
  const defaultQuery = that.state.defaultQuery || {};
  getHeader(categoryTab).then(header => {
    setFilterFunc(header, that, categoryTab, { defaultQuery });
    that.enableMenuButton && that.enableMenuButton('config');
  });
};
// 筛选设置保存
const handleNewFilterConfigOk = (that, filterMoreRef, para, defaultQuery, succCb) => {
  const vTypeset = filterMoreRef?.vContent ?? {};
  const { rightList = [], oriRightList } = vTypeset.state;
  const deleteList = oriRightList.filter(item => !rightList.includes(item.key));
  if (rightList.length > MAX_TABLE_FILTER_ITEMS) {
    createTip(`筛选区最多可显示${MAX_TABLE_FILTER_ITEMS}个字段，请重新选择`, INFO).show();
    return false;
  }
  filterMoreRef?.vContent?.loadingOn();
  callApi({
    method: 'POST',
    path: 'Table/Index/setTable',
    body: {
      req: JSON.stringify({ search_filter: rightList, ...para }),
    },
  }).then(res => {
    if (isset(res, 'data.errno') === 0) {
      filterMoreRef?.vContent?.loadingOff();
      createTip('操作成功', CHECK).show();
      if (that.getAllFitlerQuery || that.setSearchFilter) {
        const filter = that.getAllFitlerQuery(true);
        const deFilter = that._designateFilter;
        const header = that.getHeader();
        // TODO filter 里的key值 有在assembly里的 要拿出来判断
        deleteList.forEach(item => {
          // 在表头不存在 或不显示时 删除筛选项
          if (header[item.key] && (header[item.key].display === undefined || header[item.key].display === 'hide')) {
            delete filter[item.key];
            delete deFilter[item.key];
          }
        });
        that.setDesignateFilter && that.setDesignateFilter(deFilter);
        that.setSearchFilter && that.setSearchFilter(filter);
      }
      /**
       * 对账工具 - 报表 - 数具报表查询 - 这面面的设置筛选项回调
       */

      succCb && succCb();
      that.handleRefreshPage && that.handleRefreshPage();
      filterMoreRef.vLazyRender.handleHide();
    } else {
      createTip(isset(res, 'data.errmsg', '') || TIPS, ERROR).show();
    }
  });
};
export const setNewFilter = function (...paramsGroup) {
  const that = paramsGroup[0];
  const event = paramsGroup[5];
  const defaultQuery = that.state.defaultQuery || {};
  const categoryTab = {
    category: that.tableInfo.category,
    tab: that.tableInfo.tab,
  };
  // 根据event.target设置挂载对象
  const getDomNode = () => {
    return event && event.target;
  };
  //  获取下拉框的数据
  const getRenderData = async () => {
    const header = await getHeader(categoryTab);
    const data = setNewFilterFunc(header, that, categoryTab, { defaultQuery, filterMore });
    return data;
  };
  const handleSubmit = filterMore => {
    handleNewFilterConfigOk(that, filterMore, categoryTab, defaultQuery);
  };
  const filterMore = new FilterMore({
    getDomNode,
    getRenderData,
    handleSubmit,
  });
  filterMore.handleShow();
};
// 筛选设置
export const setNewFilterFunc = function (h, that, categoryTab, ext, succCb) {
  const header = h && h.header ? h.header : h;
  if (!header) return;
  let filterKeys;
  if (ext && ext.filterKeys) {
    filterKeys = ext.filterKeys || [];
  } else {
    filterKeys = Object.keys(that.getOriFilter());
  }
  // 在「筛选设置」中隐藏字段，目前在「客户运营报表」和 「项目运营报表」使用到了该字段
  // 主要场景是，列表中存在两个相同中文名称的列，在筛选设置里需要隐藏一个列。
  // that可能存在2种值，当为ListPage实例的时候，才走隐藏逻辑
  const forceHideFilter = Object.values(that?.getHeader?.() ?? {})
    .filter(item => _.get(item, 'filterProps.forceHideInSetFilter'))
    .map(item => item.columnKey);
  const defaultQuery = Object.keys(ext.defaultQuery || {}) || [];
  const rightList = filterKeys
    .map(key => (header[key] ? { key, title: header[key].title } : undefined))
    .filter(item => item);
  const leftList = [];
  Object.entries(header).forEach(([key, value]) => {
    if ((forceHideFilter && forceHideFilter.includes(key)) || header[key]?.display !== 'show') return;
    if (`${value.filterable}` === 'true' && !filterKeys.includes(key) && !defaultQuery.includes(key)) {
      leftList.push({ key, title: value.title });
    }
  });
  const hideEleTitle = [];
  const hideEle = [];
  defaultQuery.forEach(item => {
    header[item] && hideEleTitle.push(header[item].title);
    header[item] &&
      hideEle.push({
        key: item,
        title: header[item].title,
      });
  });
  return {
    leftList,
    rightList,
    hideEle,
  };
};
// 排序设置保存
const handleSortConfigOk = (that, getvModalDialog, getvTypeset, para, ext) => {
  const vTypeset = getvTypeset();
  const { rightList } = vTypeset.state;
  const body = {};
  for (const arrItem of rightList) {
    body[arrItem.sortKey] = arrItem.sortType;
  }
  if (rightList.length > MAX_TABLE_SOTR_ITEMS) {
    createTip(`最多只能设置${MAX_TABLE_SOTR_ITEMS}个排序规则`, INFO).show();
    return false;
  }
  getvModalDialog && getvModalDialog.submitBtnRef && getvModalDialog.submitBtnRef.loadingOn();
  callApi({
    method: 'POST',
    path: 'Table/Index/setTable',
    body: {
      req: JSON.stringify({ sort: { ...body }, ...para }),
    },
  }).then(res => {
    if (isset(res, 'data.errno') === 0) {
      createTip('操作成功', CHECK).show();
      that.resetSort && that.resetSort();
      that.handleRefreshPage && that.handleRefreshPage();
      ext && ext.callback && ext.callback();
      getvModalDialog.vModalDialog.handleHide();
    } else {
      createTip(isset(res, 'data.errmsg', '') || TIPS, ERROR).show();
      getvModalDialog && getvModalDialog.submitBtnRef && getvModalDialog.submitBtnRef.loadingOn();
    }
  });
};
//  排序设置
export const setSortFunc = function (h, sort = {}, that, para, ext) {
  let vTypset = null;
  // 为了兼容 表头设置
  const header = h && h.header ? h.header : h;
  if (!header) return;
  let leftList = [];
  const getvTypet = () => vTypset;
  const sortKey = Object.keys(sort);
  const showKey = [];
  const hideKey = [];
  const rightList = []; // left未选中字段 right选中字段
  for (const [key, value] of Object.entries(header)) {
    const unDisplay = value.display === 'hide';
    // if (((value.display === 'show' || unDisplay) && `${value.sortable}` === 'true') || (value.display === 'show' || `${value.sortable}` === 'front_only')) {
    if (
      (unDisplay && `${value.sortable}` === 'true') ||
      (value.display === 'show' && `${value.sortable}` !== 'false')
    ) {
      showKey.push(key);
      unDisplay && hideKey.push(key);
      const tmpArr = [
        {
          key: `${key}_asc`,
          sortKey: key,
          title: `${value.title}升序`,
          sortType: SORTTYPES.ASC,
          className: unDisplay ? 'hide_key' : '',
        },
        {
          key: `${key}_desc`,
          sortKey: key,
          title: `${value.title}降序`,
          sortType: SORTTYPES.DESC,
          className: unDisplay ? 'hide_key' : '',
        },
      ];
      let tmpObj = {};
      if (sortKey.includes(key)) {
        sort[key] === SORTTYPES.DESC ? ([tmpObj] = tmpArr) : ([, tmpObj] = tmpArr);
        leftList.push(tmpObj);
      } else {
        leftList = [...leftList, ...tmpArr];
      }
    }
  }
  const sortConfig = { desc: '降序', asc: '升序' };
  for (const [key, value] of Object.entries(sort)) {
    if (showKey.includes(key)) {
      // 如果排序的key不在显示的key中，不显示
      rightList.push({
        key: `${key}_${value}`,
        sortKey: key,
        title: `${header[key].title}${sortConfig[value]}`,
        sortType: value,
        className: hideKey.includes(key) ? 'hide_key' : '',
      });
    }
  }
  const popParam = {
    isShow: true,
    title: '排序设置',
    autoDestroy: true,
    isModal: ext?.isModal ?? false, // 默认值是 false
    hasReset: ext !== undefined && ext.handleReset !== undefined,
    handleSubmit: typesetDialog => {
      ext && ext.handleOk && ext.handleOk(that, typesetDialog, getvTypet, para, ext);
      !(ext && ext.handleOk) && handleSortConfigOk(that, typesetDialog, getvTypet, para, ext);
    },
    headerTip:
      '* 灰色字段在当前列表不显示，为避免在查看列表时看不出排序规则，请尽量按显示字段排序。前端排序字段显示时才可设置。',
    typesetProps: {
      ref: r => (vTypset = r),
      maxCountTip: `最多只能设置${MAX_TABLE_SOTR_ITEMS}个排序规则`,
      titleType: 'setSort',
      isSort: true,
      // leftItemProps: { className: 'hide_key' },
      // rightItemProps: { className: 'hide_key' },
      leftListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
      },
      rightListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
      },
      uniqueKey: 'key',
      leftList,
      rightList,
    },
  };
  ext &&
    ext.handleReset &&
    (popParam.handleReset = typesetDialog => {
      ext.handleReset(that, typesetDialog, getvTypet, para, ext);
    });
  new PopUp(PopupTypeset, popParam).show();
};
//  新排序设置
export const setNewSortFunc = function (h, sort = {}, that, para, ext) {
  const vTypset = React.createRef();
  // 为了兼容 表头设置
  const header = h && h.header ? h.header : h;
  if (!header) return;
  let leftList = [];
  const getvTypet = () => vTypset?.current;
  const showKey = [];
  const hideKey = [];
  const rightList = []; // left未选中字段 right选中字段
  for (const [key, value] of Object.entries(header)) {
    const unDisplay = value.display === 'hide';
    if (
      (unDisplay && `${value.sortable}` === 'true') ||
      (value.display === 'show' && `${value.sortable}` !== 'false')
    ) {
      showKey.push(key);
      unDisplay && hideKey.push(key);
      const tmpArr = [
        {
          key: `${key}`,
          sortKey: key,
          title: `${value.title}`,
          sortType: SORTTYPES.ASC,
          className: unDisplay ? 'hide_key' : '',
        },
      ];
      leftList = [...leftList, ...tmpArr];
    }
  }
  const sortConfig = { desc: '降序', asc: '升序' };
  for (const [key, value] of Object.entries(sort)) {
    if (showKey.includes(key)) {
      // 如果排序的key不在显示的key中，不显示
      rightList.push({
        key: `${key}`,
        sortKey: key,
        title: `${header[key].title}`,
        sortType: value,
        className: hideKey.includes(key) ? 'hide_key' : '',
      });
    }
  }
  console.log('leftList', leftList);
  console.log('rightList', rightList);
  const popParam = {
    isShow: true,
    title: '排序设置',
    autoDestroy: true,
    hasReset: ext !== undefined && ext.handleReset !== undefined,
    handleSubmit: typesetDialog => {
      ext && ext.handleOk && ext.handleOk(that, typesetDialog, getvTypet, para, ext);
      !(ext && ext.handleOk) && handleSortConfigOk(that, typesetDialog, getvTypet, para, ext);
    },
    headerTip:
      '* 灰色字段在当前列表不显示，为避免在查看列表时看不出排序规则，请尽量按显示字段排序。前端排序字段显示时才可设置。',
    typesetProps: {
      ref: vTypset,
      maxCountTip: `最多只能设置${MAX_TABLE_SOTR_ITEMS}个排序规则`,
      titleType: 'setSort',
      isSort: true,
      // leftItemProps: { className: 'hide_key' },
      // rightItemProps: { className: 'hide_key' },
      leftListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
      },
      rightListProps: {
        showHeader: false,
        tableHeader: {
          title: 'title',
        },
      },
      uniqueKey: 'key',
      leftList,
      rightList,
    },
  };
  ext &&
    ext.handleReset &&
    (popParam.handleReset = typesetDialog => {
      ext.handleReset(that, typesetDialog, getvTypet, para, ext);
    });
  new PopUp(PopupSortSet, popParam).show();
};
// 列表页调用排序设置
export const setSort = function (that, ext) {
  that.disableMenuButton && that.disableMenuButton('config');
  const categoryTab = {
    category: that.tableInfo.category,
    tab: that.tableInfo.tab,
    items: ['header', 'sort'],
  };
  // const sort = that.getSort()
  const isNewTableStyle = getIsNewTableStyle();
  let handleSetSort = setSortFunc;
  getHeader(categoryTab, 'data.res').then(res => {
    if (isNewTableStyle) {
      handleSetSort = setNewSortFunc;
    }
    handleSetSort(
      res.header,
      res.sort,
      that,
      {
        category: that.tableInfo.category,
        tab: that.tableInfo.tab,
      },
      ext,
    );
    that.enableMenuButton && that.enableMenuButton('config');
  });
};
export const callee = function (result, reader) {
  if (result.value) {
    const fr = new FileReader();
    fr.readAsText(new Blob([result.value]));
    fr.onload = function () {
      document.body.insertAdjacentHTML('beforeend', fr.result);
    };
  }
  if (result.done) {
    // 完成
    document.body.insertAdjacentHTML('beforeend', 'ok');
  } else {
    // 异步递归
    reader.read().then(callee);
  }
};
// export const exportSelected = function (that) {
//   fnExportSelect(that)
// }
// 设置表格(保存表格样式)
export const setTable = async (category, tab, body, callback) => {
  const req = {
    category,
    tab,
    fetch_mode: 'config',
    ...body,
  };
  const res = await callApi({
    path: 'Table/Index/setTable',
    method: 'POST',
    body: {
      req: JSON.stringify(req),
    },
  });
  if (isset(res, 'data.errno') === 0) {
    createTip('操作成功', CHECK).show();
    if (typeof callback === 'function') callback();
  } else {
    createTip('操作失败', ERROR).show();
  }
};
// 列表页打印
// export const apiPrintTpl = (resDt, selected, odLinkIdList, printCallBack, ext) => {
//   let resResult = resDt.res, printSlnDgRef = null    // eslint-disable-line
//   if (resResult) {
//     resResult.data.printer.otherProps.data = LodopFunc.getAllPrinters(LodopFunc.getLodop())
//   }
//   new PopUp(printSlnDialog, {
//     dialogKey: 'printSln',
//     dialogInfo: resResult || {},
//     cid: window.globalPrintHostId,
//     ref: (r) => printSlnDgRef = r,
//     tplId: selected.tpl_key || selected,
//     closePrintSln: (where) => {
//       if (where === 'confirm') {
//         actions.orderServer.getPrintInfo({
//           cid: window.globalPrintHostId,
//           tpl_id: selected.tpl_key,
//           fields_type: selected.tpl_type,
//           ids: odLinkIdList,
//           company_id: ext && ext.company_id,
//         }).then(rest => printCallBack(rest.data, selected, odLinkIdList, ext))
//       } else {
//         printSlnDgRef = null
//       }
//     }
//   }).show()
// }
// export const printSln = (parm) => {
//   const { tplId, selected, odLinkIdList, handleApiResPrint, ext } = parm

//   actions.orderServer.getPrintSln({
//     cid: window.globalPrintHostId,
//     tpl_id: tplId,
//   }).then(rest => apiPrintTpl(rest.data, selected, odLinkIdList, handleApiResPrint, ext))
// }

// 列表装车清单，37008
const trLoadingList = async (
  that,
  key,
  rowIndex,
  col,
  selected,
  odLinkIdList,
  companyIds,
  _companyId,
  upDownType,
  tmpSlnSet,
) => {
  console.log(that, key, rowIndex, col, selected, odLinkIdList, _companyId);

  const printSlnDg = (getPrintSlnRes, tmpSlnCb) =>
    new Promise(resolve => {
      let cancelable = true;
      const resResult = getPrintSlnRes.res;
      if (resResult) {
        resResult.data.printer.otherProps.data = LodopFunc.getAllPrinters(LodopFunc.getLodop());
      }
      const popup = (window.printSlnTrLoad = new PopUp(printSlnDialog, {
        dialogKey: 'printSln',
        dialogInfo: resResult || {},
        cid: window.globalPrintHostId,
        tplId: selected.tpl_key,
        isModal: true,
        popName: 'printSlnTrLoad',
        callback: pack => {
          cancelable = false;
        },
        onClose: () => cancelable && resolve(false),
        closePrintSln: (where, testSlnSet) => {
          if (where === 'confirm') {
            if (testSlnSet) {
              tmpSlnCb(testSlnSet);
            } else {
              resolve(true);
            }
          } else if (where === 'cancel') {
            resolve(false);
          }
        },
      }).show());
    });

  const fetchSlnUrl = '/Basic/Print/getPrintSln';
  const fetchInfoUrl = '/Basic/Print/getPrintInfo';
  for (const odLinkId of odLinkIdList) {
    const odLinkIdAry = [odLinkId];
    const reqObj = {
      req: {
        cid: window.globalPrintHostId,
        tpl_id: selected.tpl_key,
        fields_type: selected.tpl_type,
        ids: odLinkIdAry,
        company_id: getPrintComId(_companyId),
        type: upDownType,
        timestamp1: Date.parse(new Date()),
        selectedKeys: isTruckLoadDetail(that) ? that.vTable.getStateSelectes(true) : null,
      },
    };
    const getPrintInfo = await fetchApi(fetchInfoUrl, { method: 'POST', body: reqObj });
    console.log('getPrintInfo = ', getPrintInfo);
    if (!getPrintInfo) return false;
    const resDt = { ...getPrintInfo };
    resDt.params = {
      selected,
      odLinkIdAry,
      companyIds,
    };
    if (!Object.keys(resDt).length) return false;
    let messageContent = '请创建运单打印模板';
    let showTip = false;
    const groupId = window[GROUP_ID];
    const printCopies = +groupId === 1002 ? 2 : 1;
    if (!resDt) {
      createTip(messageContent, ERROR).show();
      return null;
    }
    if (resDt.errno < 0) {
      messageContent = resDt.errmsg;
      showTip = true;
    }
    const oriTplSet = resDt.res.tpl_setting;
    const tplSet = !tmpSlnSet ? oriTplSet : { ...oriTplSet, ...tmpSlnSet };
    if (+tplSet.length === 0 || showTip) {
      createTip(messageContent, ERROR).show();
      return null;
    }
    if (tplSet.has_sln === 1) {
      // 是否有打印方案 -->  有
      let tplData = resDt.res.tpl_data;
      if (tplSet.jsscript) {
        // eslint-disable-next-line no-eval
        const func = eval(tplSet.jsscript);
        tplData = func?.(tplData, _, resDt.req) || tplData;
      }
      for (let i = 0; i < tplData.length; i++) {
        for (let j = 0; j < printCopies; j++) {
          const isUTD = tplSet.category === 0;
          const tplSetDetail = isUTD ? groupIdxSum({ tplSet, idx: i, total: tplData.length }) : tplSet.detail;
          const printProxy = {
            LODOP: window.globalLodopObj,
            data: tplData[i],
            printSetting: tplSet,
            tplDetail: tplSetDetail,
            needCheck: false,
            dataSum: Math.max(tplData.length, printCopies, odLinkIdList.length),
          };

          isUTD ? await PrintManager.untdTablePrint(printProxy) : await PrintManager.tablePrint(printProxy);
        }
      }
      console.log('tplSet.has_sln === 1');
    } else {
      // 没有打印方案，获取打印方案
      const reqSlnObj = {
        req: {
          cid: window.globalPrintHostId,
          tpl_id: selected.tpl_key,
        },
      };
      const getPrintSln = await fetchApi(fetchSlnUrl, { method: 'POST', body: reqSlnObj });
      console.log('getPrintSln = ', getPrintSln);
      if (!getPrintSln) return false;

      const printSlnCb = params =>
        trLoadingList(that, key, rowIndex, col, selected, odLinkIdList, companyIds, _companyId, upDownType, params);
      const userSlnRes = await printSlnDg(getPrintSln, printSlnCb);
      console.log('userSlnRes =', userSlnRes);
      if (!userSlnRes) return false;
      userSlnRes && trLoadingList(that, key, rowIndex, col, selected, odLinkIdList, companyIds, _companyId, upDownType);
      return false;
    }
  }
  // for end
};

const isFromPickClip = listPage => listPage && listPage.getReq().tab === 'pick_clip'; // 是否是挑单夹事件
const isTruckLoadDetail = listPage => {
  return ['truck_load_detail', 'delivery_load_detail', 'pickup_load_detail'].includes(listPage?.getReq?.()?.tab);
}; // 是否是装车清单
const getData = (keys, that) => {
  const obj = {};
  keys.forEach(v => (obj[v] = []));
  // 挑单事件&1.0运单详情装车清单打印取全部数据
  if (isFromPickClip(that) || isTruckLoadDetail(that)) {
    const data = that.getData();
    data.forEach((item, index) => {
      keys.forEach(key => {
        if (!obj[key]) {
          obj[key] = [];
        }
        obj[key][index] = item[key];
      });
    });
  } else {
    keys.forEach(v => (obj[v] = that.getSelectesKey(v)));
  }
  return obj;
};
export const printTableList = async (that, key, rowIndex, col, theSelected) => {
  const selected = theSelected;
  let keyWord = 'b_link_id';
  let args = ['batch'];
  if (key === 'print_order') {
    keyWord = 'od_link_id';
    args = 'order';
  } else if (key === 'print_task') {
    keyWord = 'id';
    args = 'task';
    // 1.0运单详情支持装车清单打印功能
    if (selected?.tpl_type === 'task_loading_list' || selected?.tpl_type === 'batch_order_loading_list') {
      keyWord = 'b_link_id';
    }
  } else if (key.includes('print_reservation')) {
    keyWord = 'id';
    args = 'order';
  } else if (theSelected && theSelected.tpl_type && theSelected.tpl_type.includes('wms_bill')) {
    keyWord = 'id';
  }

  const tableInfo = !isFromPickClip(that) ? that.getTableInfo() : window.menu[0].child[0];
  const category = fromJS(tableInfo).getIn(['pageConfig', 'tableInfo', 'category']);
  const tab = fromJS(tableInfo).getIn(['pageConfig', 'tableInfo', 'tab']);
  const upDownType = category === 'Batch' && tab === 'tr_down' ? 2 : undefined;
  keyWord = category && mockData.printList[category] ? mockData.printList[category].ids : keyWord;
  const {
    company_id: companyIds,
    com_id: comIds,
    [keyWord]: odLinkIdList,
    group_id: gIds,
  } = getData([keyWord, 'company_id', 'com_id', 'group_id'], that);
  const _comId = remDub(companyIds.map(v => String(v))).filter(v => v !== 'undefined' && v !== 'null'); // 去重
  const comId = remDub(comIds.map(v => String(v))).filter(v => v !== 'undefined' && v !== 'null');
  if (!checkSelected(odLinkIdList, '至少选择一条运单', false)) return;
  if (!checkComId(_comId)) return;
  const _companyId = getPrintComId(_comId[0]?.trim() || comId[0]); //  字段不一致
  if (selected.tpl_key === 'factory_tag') {
    const gId = [...new Set(gIds)];
    if (gId.length > 1) {
      showInfo(ERROR, '只能选择同一个承运商的运单进行打印');
      return false;
    }
    const factoryTagTpl = await fetchApi('/Basic/Print/getFactoryTagTpl', {
      method: 'POST',
      body: {
        req: {
          group_id: gId[0],
        },
      },
    });
    if (factoryTagTpl.errno === 0) {
      selected.tpl_key = factoryTagTpl.res.tpl_id;
    } else {
      showInfo(ERROR, factoryTagTpl.errmsg);
      return false;
    }
  }
  // 列表装车清单，37008
  if (selected.tpl_type === 'tr_loading_list' || selected.tpl_type === 'task_loading_list') {
    const uniqueIdList = key === 'print_task' ? remDub(odLinkIdList) : odLinkIdList;
    trLoadingList(that, key, rowIndex, col, selected, uniqueIdList, companyIds, _companyId, upDownType);
    return false;
  }

  actions.orderServer
    .getPrintInfo({
      cid: window.globalPrintHostId,
      tpl_id: selected.tpl_key,
      fields_type: selected.tpl_type,
      ids: odLinkIdList,
      company_id: _companyId,
      group_id: gIds[0],
      selectedKeys: isTruckLoadDetail(that) ? that.getSelectesKey() : null,
    })
    .then(rest => {
      const res = rest.data;
      res.params = {
        selected,
        odLinkIdList,
        companyIds,
      };
      if (res.errno !== 0) {
        showInfo(ERROR, res.errmsg);
      } else {
        handleApiResPrint(res, selected, odLinkIdList, args, { company_id: _companyId, group_id: gIds[0] });
      }
    });
};
// 打印往返运单
export const printReturnTask = async (that, key, rowIndex, col, selected) => {
  const args = 'task';
  const { tab } = that.tableInfo;
  let selectList = [];
  // 按圈审核详情、往返编号详情打印不需要勾选
  if (tab === 'b_fee_list_detail_for_batch' || tab === 'tt_report_detail') {
    selectList = that.getData();
  } else {
    selectList = that.getStateSelecteRows?.();
  }
  // 每个往返单只打印一次
  selectList = _.uniqBy(selectList, 'return_num');
  if (!selectList.length) return showInfo(WARN, '至少选择一条数据');
  new PopUp(BatchOp, {
    title: '批量打印',
    requestUrl: '/Basic/Print/getPrintInfo',
    tips: '打印',
    data: selectList,
    header: {
      return_num: { title: '往返编号' },
    },
    getPara: dataList => {
      const { return_num, company_id, group_id } = dataList[0] || {};
      return {
        cid: window.globalPrintHostId,
        tpl_id: selected?.tpl_key,
        fields_type: selected?.tpl_type,
        ids: [return_num],
        company_id: company_id?.[0],
        group_id,
      };
    },
    paceCallback: res => {
      const { company_id, ids: odLinkIdList, group_id } = res?.req || {};
      res.params = {
        selected,
        odLinkIdList,
        companyIds: [company_id],
      };
      handleApiResPrint(res, selected, odLinkIdList, args, { company_id, group_id });
    },
    escBind: false,
  }).show();
};
/*
 * that 是indexPage页面才会有的信息，通过页面点击进入的侧拉没有that
 * selected 为sublist里需要的内容
 * docId 获取indexPage的doc_id
 * */
export const printAccounts = function (that, key, rowIndex, col, selected, docId) {
  // let thisLodop = null
  const fieldsType = 'accounts';
  if (that) {
    const docIdList = that.getSelectesKey('doc_id');
    if (!checkSelected(docIdList, '至少选择一条运单', false)) return;
    actions.orderServer
      .getPrintInfo({
        cid: window.globalPrintHostId,
        tpl_id: selected.tpl_key || selected,
        fields_type: fieldsType,
        company_id: getPrintComId(),
        ids: docIdList,
      })
      .then(rest => handleApiResPrint(rest.data, selected, docIdList, fieldsType));
  } else {
    actions.orderServer
      .getPrintInfo({
        cid: window.globalPrintHostId,
        tpl_id: selected.tpl_key || selected,
        fields_type: fieldsType,
        ids: [docId],
        company_id: getPrintComId(),
      })
      .then(rest => handleApiResPrint(rest.data, selected, [docId], fieldsType));
  }
};

/*
 * 财务清单打印
 * */
function showShowInfo(content, title, callback) {
  const popup = new PopUp(ShowInfo, {
    classname: 'print-error',
    isShow: true,
    type: INFO,
    content,
    autoDestroy: true,
    title: title || '确定',
    simpleMode: {
      cancel: thisDialog => {
        thisDialog.handleHide();
        if (typeof callback === 'function') callback();
      },
    },
    closable: true,
    maskClosable: false,
  });
  popup.show();
}

function showContinueShowInfo(content, title, continueCallback) {
  const popup = new PopUp(ShowInfo, {
    classname: 'print-error',
    isShow: true,
    type: INFO,
    content,
    autoDestroy: true,
    title: title || '确定',
    buttons: [
      {
        name: '继续',
        btnType: 'primary',
        cb: thisDialog => {
          thisDialog.changeBtnLoadingStatus('confirm', true);
          // 需在callback中调用thisDialog.handleHide()来关闭弹框
          continueCallback &&
            continueCallback(() => {
              thisDialog.changeBtnLoadingStatus('confirm', false);
              thisDialog.handleHide();
            });
        },
      },
      {
        name: '取消',
        btnType: 'default',
        cb: thisDialog => {
          thisDialog.handleHide();
        },
      },
    ],
    closable: true,
    maskClosable: false,
  });
  popup.show();
}

const printFiltCommand = async (fileType, basePath, proxy) => {
  if (fileType === 'image') {
    await PrintManager.printImgFile({
      ...proxy,
      basePath,
    });
  } else if (fileType === 'pdf') {
    await PrintManager.printPdfFile({
      ...proxy,
      basePath,
    });
  }
};

const attachmentOptionHandle = async (file, proxy, ossConfig, support_attachment_type = []) => {
  const { isOss, name, path } = file;
  let basePath = null;
  const fileType = getFileType(name);

  const attachmentTypeMap = {
    pdf: 1,
    image: 2,
  };

  if (isOss) {
    const param = {
      gid: getGId(),
      filename: path,
      // 后端配置：目前 bill、balance 可以下载文件
      type: 'bill',
      // 文件名
      showname: encodeURIComponent(name),
    };
    const { origin } = window.location;
    const downLoadUrl = 'api/Basic/File/getFile';
    const req = JSON.stringify(param);
    const otherParam = `req=${req}`;
    basePath = `${origin}/${downLoadUrl}?${otherParam}`;
    // basePath = `${ossConfig.host}/${path}`;
  } else if (fileType === 'image') {
    basePath = `${window.location.origin}/api/Basic/Image/getImage?req=${JSON.stringify(file)}&gid=${getGId()}`;
  } else if (fileType === 'pdf') {
    basePath = `${window.location.origin}/api/Basic/File/getFile?req=${JSON.stringify(file)}&gid=${getGId()}`;
  }
  if (!support_attachment_type?.includes(attachmentTypeMap[fileType])) {
    return;
  }
  printFiltCommand(fileType, basePath, proxy);
};

const handlePrintfFile = async (proxy, ossConfig) => {
  const { data, printSetting } = proxy;
  const { fields_setting, support_attachment_type } = printSetting;
  const fileSetting = Object.entries(fields_setting ?? {});
  const files = fileSetting.filter(item => item[1].type === 'attachment');
  const keys = files.map(item => item[0]);

  const fileList = keys.map(item => {
    return {
      file: _.get(data, item),
    };
  });
  for (let i = 0; i < fileList.length; i++) {
    const el = fileList[i];
    el.file.forEach(item => {
      attachmentOptionHandle(item, proxy, ossConfig, support_attachment_type);
    });
  }
};

const handleBillApiResPrint = async proxy => {
  const { resDt, selected, orderIds, oriIds } = proxy;
  let messageContent = '请创建财务清单打印模板';
  let showTip = false;
  const printCopies = 1;
  const tplId = selected && selected.tpl_key;
  if (resDt.errno < 0) {
    messageContent = resDt.errmsg;
    showTip = true;
  }
  const apiTplSet = resDt.res.tpl_setting;
  const printAttachmentOnly = apiTplSet?.print_attachment_only;
  const tplSet = +apiTplSet.has_sln === 1 ? apiTplSet : window[tplId] || apiTplSet;
  if (!tplSet || tplSet.length < 1 || showTip) {
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: ERROR,
      isShow: true,
      content: messageContent,
      autoDestroy: true,
    }).show();
    return false;
  }
  if (tplSet.has_sln !== 1) {
    // 是否有打印方案
    const slnRes = await actions.orderServer.getPrintSln({
      cid: window.globalPrintHostId,
      tpl_id: tplId,
    });
    const setSlnRes = await apiPrintTpl(slnRes.data, selected, orderIds, handleBillApiResPrint);
    console.log(setSlnRes);
  } else {
    !window[tplId] && (window[tplId] = tplSet);
    const resTplData = resDt.res.tpl_data;
    let ossConfig = null;
    try {
      ossConfig = await getNtoccOssKey(true);
    } catch (error) {
      console.log(error);
      ossConfig = null;
    }
    for (let i = 0; i < printCopies; i++) {
      for (let k = 0; k < resTplData.length; k++) {
        const currTplData = resTplData[k];
        const isUTD = tplSet.category === 0;
        const tplSetDetail = isUTD ? groupIdxSum({ tplSet, idx: k, total: resTplData.length }) : tplSet.detail;
        const printProxy = {
          LODOP: window.globalLodopObj,
          data: currTplData,
          printSetting: tplSet,
          tplDetail: tplSetDetail,
          needCheck: false,
          dataSum: Math.max(resTplData.length, printCopies, oriIds.length),
        };

        if (!printAttachmentOnly) {
          isUTD ? await PrintManager.untdTablePrint(printProxy) : await PrintManager.tablePrint(printProxy);
        }
        handlePrintfFile(printProxy, ossConfig);
      }
    }
    // console.log('调用printDialog', resDt)
  }
};
// 单据中心多打印模板数据打印
const handleDocApiResPrint = async proxy => {
  const printCopies = 1;
  const { resDt, idAry } = proxy;
  if (!resDt.res.length) {
    showInfo(INFO, '没有要打印的数据', true, undefined, false);
    return false;
  }
  const errArr = [];
  for (const item of resDt.res) {
    const tplSet = item.tpl_setting;
    const billInfo = (item.tpl_data && item.tpl_data.batch) || {};
    if (!tplSet || !billInfo.bill_id) {
      billInfo.bill_no && errArr.push(billInfo.bill_no);
    } else if (tplSet.has_sln !== 1) {
      // 是否有打印方案
      const selected = {
        children: false,
        key: 'print_bill',
        title: tplSet.tpl_name,
        tpl_key: tplSet.id,
        tpl_type: tplSet.type,
      };
      const rest = await actions.orderServer.getPrintSln({
        cid: window.globalPrintHostId,
        tpl_id: tplSet.tpl_id,
      });
      await apiPrintTpl(rest.data, selected, [billInfo.bill_id], handleBillApiResPrint);
    } else {
      for (let i = 0; i < printCopies; i++) {
        const isUTD = tplSet.category === 0;
        const printProxy = {
          LODOP: window.globalLodopObj,
          data: item.tpl_data,
          printSetting: tplSet,
          tplDetail: tplSet.detail,
          needCheck: false,
          dataSum: Math.max(resDt.res.length, printCopies, idAry.length),
        };

        isUTD ? await PrintManager.untdTablePrint(printProxy) : await PrintManager.tablePrint(printProxy);
      }
    }
  }
  if (errArr.length > 0) {
    showInfo(ERROR, `${errArr.join('、')}无法打印，请创建对应类型的打印模板！`, true, undefined, false);
    return false;
  }
};
export const handlePrintBill = ({ companyIds, ids, selected, curHead, tableData, title }) => {
  const args = ['batch'];
  const _comId = remDub(companyIds); // 去重
  if (!checkSelected(ids, `至少选择一条${title ?? '运单'}`, false)) return;
  if (!checkComId(_comId)) return;
  let subIds = [];
  let batchOpRef = null;
  console.log('noPrint =', noPrintBill);
  const printData = tableData.filter(x => !noPrintBill.includes(x.customer_type));
  if (!printData.length) {
    createTip('您选择的数据暂时不支持自定义打印', ERROR).show();
    return false;
  }
  new PopUp(BatchOp, {
    title: '批量打印',
    requestUrl: '/Basic/Print/getPrintInfo',
    tips: '打印',
    // data: tableData,
    data: printData,
    header: curHead,
    ref: r => (batchOpRef = r),
    getPara: dataList => {
      console.log(dataList);
      subIds = dataList.map(v => (_.has(v, 'bill_id') ? v.bill_id : v.id));
      return {
        cid: window.globalPrintHostId,
        ids: subIds,
        company_id: getPrintComId(_comId[0]),
        tpl_id: selected.tpl_key,
        fields_type: selected.tpl_type,
        timestamp1: Date.parse(new Date()),
      };
    },
    paceCallback: async res => {
      console.log('paceCallback', res);
      if (res.errno === 0) {
        const resData = res.res || {};
        const resObj = Array.isArray(resData) ? resData[0] || {} : resData || {};
        const tplSetObj = resObj.tpl_setting || {};
        const { pop_preview } = tplSetObj;

        const proxy = { resDt: res, selected, orderIds: subIds, oriIds: ids };
        await handleBillApiResPrint(proxy);

        if (pop_preview && ids.length === 1) {
          batchOpRef && batchOpRef.handleFinish && batchOpRef.handleFinish();
        }
      }
      const successId = res.res.success_id || [];
      if (res.errno !== 0 && successId.length < 1) {
        showShowInfo(res.errmsg, '打印');
        return false;
      }
    },
    escBind: false,
  }).show();
};

export const commonPrint = function (that, key, rowIndex, col, selected) {
  const tableInfo = that.getTableInfo();
  const { category, tab } = tableInfo?.pageConfig?.tableInfo ?? {};
  const configKey = `${category}_${tab}_${key}`;

  const printConfig = mockData.printConfig[configKey];
  if (!printConfig) return;
  const { ids: keyWord, company_id, printHead, title } = printConfig;

  const indexs = that.getSelectesKey();
  const tableShowData = that.getShowingData(indexs);
  const selcDataOri = that.getStateSelecteRows();

  const idList = that.getSelectesKey(keyWord);
  const companyIds = that.getSelectesKey(company_id);

  // 实际显示值的列表
  const tableData = selcDataOri.reduce(
    (all, curr, curIndex) => all.concat([{ ...curr, ...tableShowData[curIndex] }]),
    [],
  );
  handlePrintBill({ companyIds, ids: idList, selected, curHead: printHead, tableData, title });
};
export const printBill = function (that, key, rowIndex, col, selected) {
  const tableInfo = that.getTableInfo();
  const category = fromJS(tableInfo).getIn(['pageConfig', 'tableInfo', 'category']);
  const { controller, action, tab } = tableInfo;
  console.log(controller, action, 'ssss');
  const headeConf = mockData.printHead[`${controller}_${action}`] || {};
  const curHead = {
    bill_no: { title: '单据号', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
  };
  // curHead[headeConf.status] = { title: '单据状态', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 }
  curHead[headeConf.amount] = {
    title: '单据金额',
    type: 'Text',
    display: 'show',
    width: 45,
    align: 'center',
    flexGrow: 1,
  };
  const keyWord = category && mockData.printList[category] ? mockData.printList[category].ids : 'bill_id';
  const idList = that.getSelectesKey(keyWord);
  const companyIds = that.getSelectesKey('company_id');
  const indexs = that.getSelectesKey();
  const tableShowData = that.getShowingData(indexs);
  const selcDataOri = that.getStateSelecteRows();
  let tableData = selcDataOri.reduce(
    (all, curr, curIndex) => all.concat([{ ...curr, ...tableShowData[curIndex] }]),
    [],
  );
  if (category === 'Settle' && tab === 'receipt_payment') {
    tableData = selcDataOri;
  }
  handlePrintBill({ companyIds, ids: idList, selected, curHead, tableData });
};
export const handlePrintDy = ({ companyIds, selected, ids }) => {
  // const args = ['batch']
  const _comId = remDub(companyIds); // 去重
  if (!checkSelected(ids, '至少选择一条运单', false)) return;
  if (!checkComId(_comId)) return;
  const pack = {
    cid: window.globalPrintHostId,
    tpl_id: selected.tpl_key,
    fields_type: selected.tpl_type,
    ids,
    company_id: getPrintComId(_comId[0]),
  };
  actions.orderServer.getPrintInfo(pack).then(async res => {
    const rest = res.data;
    if (rest.errno === 0) {
      const proxy = { resDt: rest, selected, orderIds: ids, oriIds: ids };
      await handleBillApiResPrint(proxy);
    }
    const successId = rest.res.success_id || [];
    if (rest.errno !== 0 && successId.length < 1) {
      showShowInfo(rest.errmsg, '打印');
      return false;
    }
    if (rest.errno !== 0 && successId.length > 0) {
      showContinueShowInfo(rest.errmsg, '打印', closeResTip => {
        closeResTip && closeResTip();
        pack.ids = successId;
        actions.orderServer.getPrintInfo(pack).then(async res2 => {
          const proxy = { resDt: res2.data, selected, orderIds: successId, oriIds: ids };
          await handleBillApiResPrint(proxy);
        });
      });
    }
  });
};
export const printDy = function (that, key, rowIndex, col, selected) {
  let keyWord = 'bill_id';
  const tableInfo = that.getTableInfo();
  const category = fromJS(tableInfo).getIn(['pageConfig', 'tableInfo', 'category']);
  keyWord = category && mockData.printList[category] ? mockData.printList[category].ids : keyWord;
  const idList = that.getSelectesKey(keyWord);
  const companyIds = that.getSelectesKey('company_id');
  handlePrintDy({ companyIds, ids: idList, selected });
};
export const documentPrint = function (that) {
  const keyWord = 'bill_id';
  const showFields = ['bill_no', 'bill_cat', 'finance_center_amount', 'customer_type'];
  const idList = that.getSelectesKey(keyWord);
  let data = that.getSelectesKey(showFields);
  data = data.map((item, index) => ({
    ...item,
    [keyWord]: idList[index],
  }));
  data = data.filter(x => !noPrintBill.includes(x.customer_type));
  if (!data.length) {
    createTip('您选择的数据暂时不支持自定义打印', ERROR).show();
    return false;
  }
  const companyIds = that.getSelectesKey('company_id');
  const _comId = remDub(companyIds); // 去重
  if (!checkSelected(idList, '至少选择一条运单', false)) return;
  if (!checkComId(_comId)) return;
  const enumerations = ['bill_cat'].reduce((enumDist, key) => {
    const currEnum = that.getTableEnum(key);
    if (isEmptyObj(currEnum)) return enumDist;
    return { ...enumDist, [key]: that.getTableEnum(key) };
  }, {});
  /* a
  if (!window.globalPrintHostId || typeof CLODOP === 'undefined') { // eslint-disable-line no-undef
    FaultDetection.detection()
    return
  }
  */
  // that.disableMenuButton && that.disableMenuButton('document_print')
  let batchOpRef = null;
  const url = '/Basic/Print/getDocPrintInfoList';
  new PopUp(BatchOp, {
    title: '批量打印',
    requestUrl: url,
    tips: '打印',
    data,
    enumerations,
    ref: r => (batchOpRef = r),
    header: {
      bill_no: { title: '单据号', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
      bill_cat: { title: '单据类别', type: 'Text', display: 'show', width: 45, align: 'center', refEnum: 'bill_cat' },
      finance_center_amount: { title: '单据金额', type: 'Text', display: 'show', width: 45, align: 'center' },
    },
    getPara: dataList => {
      const ids = dataList.map(v => v.bill_id);
      const curComId = typeIs(_comId[0], 'object') ? _comId[0].id : _comId[0];
      return {
        cid: window.globalPrintHostId,
        ids,
        company_id: curComId,
      };
    },
    paceCallback: async res => {
      if (res.errno === 0) {
        const resData = res.res || {};
        const resObj = Array.isArray(resData) ? resData[0] || {} : resData || {};
        const tplSetObj = resObj.tpl_setting || {};
        const { pop_preview } = tplSetObj;
        await handleDocApiResPrint({ resDt: res, idAry: idList });
        if (pop_preview && idList.length === 1) {
          batchOpRef && batchOpRef.handleFinish && batchOpRef.handleFinish();
        }
      }
      const successId = res.res.success_id || [];
      if (res.errno !== 0 && successId.length < 1) {
        showShowInfo(res.errmsg, '打印');
        return false;
      }
    },
    escBind: false,
  }).show();
};
export const printFnConfig = (key, parm) => {
  const { that, rowIndex, col, selected, docId } = parm;
  // 运单自定义打印
  key === 'print_order' && printTableList(that, key, rowIndex, col, selected);
  key === 'print_list' && printTableList(that, key, rowIndex, col, selected);
  key === 'oms_print_reservation' && printTableList(that, key, rowIndex, col, selected);
  key === 'print_reservation' && printTableList(that, key, rowIndex, col, selected);
  // 会计打印
  key === 'print_accounts' && printAccounts(that, key, rowIndex, col, selected, docId);
  // 财务清单
  key === 'print_bill' && printBill(that, key, rowIndex, col, selected);
  // 单据中心批量打印
  key === 'document_print' && documentPrint(that);
  // 代收换票
  key === 'print_dy' && printDy(that, key, rowIndex, col, selected);
  // 任务打印
  key === 'print_task' && printTableList(that, key, rowIndex, col, selected);
  // 往返任务打印
  key === 'print_return_task' && printReturnTask(that, key, rowIndex, col, selected);
  // 车辆记录打印
  key === 'print_truckrecord' && commonPrint(that, key, rowIndex, col, selected);
};

export const printFn = async function (that, key, rowIndex, col, selected, docId) {
  if (!(await checkLODOP())) return false;
  const parm = { that, key, rowIndex, col, selected, docId };
  printFnConfig(key, parm);
};

export const photoUpload = function (that) {
  const selectLink = that.getSelectesKey(['od_link_id', 'order_num']);
  const upLoadCb = rst => {
    const photoImgs = rst; // 所选的图片信息
    const photoNum = rst.name; // 图片的索引
    const pickInfo = rst.pickInfo || {};
    const isVisible = pickInfo.is_visible || false ? 1 : 0;
    // 现在只支持回单和运单跟踪类型
    const url = pickInfo.photo_type === 'order' ? '/Order/Order/uploadSfStateFollowImgs' : '/Order/Order/uploadImgs';
    if (!photoNum) return false;
    const req =
      pickInfo.photo_type === 'order'
        ? { od_link_id: pickInfo.order_num, is_visible: isVisible }
        : { od_link_id: pickInfo.order_num };
    const imgKey = pickInfo.photo_type === 'order' ? 'state_follow_img[]' : 'receipt_img[]';
    const _formData = new FormData(); // new formdata
    _formData.append('req', JSON.stringify(req));
    _formData.append(imgKey, photoImgs.formdata);
    fetchApi(url, { method: 'POST', body: _formData, credentials: 'include' })
      .then(res => {
        if (res.errno === 0) {
          createTip('拍照上传成功', CHECK, () => {}, 2000, 'tops', true).show();
        } else {
          createTip(res.errmsg || '拍照上传失败', ERROR, () => {}, 2000, 'tops', true).show();
        }
        return false;
      })
      .catch(error => {
        console.log(error);
      });
  };
  new PopUp(UploadImgCamera, {
    upLoadCb,
    isModal: true,
    showPickOrder: true,
    orders: selectLink,
    popName: 'camer',
  }).show();
};

const changeHeaderLevel = async (that, btnkey) => {
  const url = '/Table/Template/switchMultiLevelHeader';
  const { category, tab } = that.tableInfo || {};
  const req = {
    category,
    tab,
    version: btnkey === 'multi_level_header' ? 'multiLevelHeader' : 'self',
  };
  const result = await fetchApi(url, { method: 'POST', body: { req } }).catch(e => null);
  if (+result?.errno === 0) {
    showInfo(CHECK, '操作成功');
    that?.refreshTab?.();
  } else {
    showInfo(ERROR, result?.errmsg || '操作失败');
  }
};

export function init(emt) {
  // 列表设置
  emt.on('setHeader', setHeader);
  // 筛选设置
  emt.on('setFilter', setFilter);
  emt.on('setNewFilter', setNewFilter);
  // 排序设置
  emt.on('setSort', setSort);
  // 打印
  emt.on('printOrder', printFn);
  emt.on('omsPrintReservation', printFn);
  emt.on('printReservation', printFn);
  emt.on('printList', printFn);
  emt.on('printTask', printFn);
  emt.on('printReturnTask', printFn);
  emt.on('printTruckrecord', printFn);
  // 会计 凭证批量打印
  emt.on('printAccounts', printAccounts);
  // 财务清单打印
  emt.on('printBill', printFn);
  // 代收换票
  emt.on('printDy', printFn);
  // 单据中心批量打印
  emt.on('documentPrint', printFn);
  // 拍照上传
  emt.on('photoUpload', photoUpload);
  emt.on('selfHeader', changeHeaderLevel);
  emt.on('multiLevelHeader', changeHeaderLevel);
}
